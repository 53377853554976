import React from 'react'
import Layout from '../components/layout'
import HubspotForm from 'react-hubspot-form'
import WebcastCarousel from '../components/WebcastCarousel/WebcastCarousel'
import PopUp from '../components/PopUp'

const registration = ({ location }) => {
    return (
        <Layout location = { location }>
            <div>
                <section class="bg-indigo-100 h-900 mx-auto py-0 relative flex justify-center items-center w-full relative">
                    
                    <div class="flex flex-col-reverse md:flex-row w-full lg:w-11/12 items-center justify-center md:justify-around p-5 pb-0 md:p-0 md:w-1/2">
                        <div class="p-0 mr-0 md:mr-8">
                            <img loading = "lazy" src="https://gatsycms.vantagecircle.com/wp-content/uploads/2022/03/VP-Registration-hero.png" alt="guests" />
                        </div>
                        <div class="p-0 flex flex-col items-start md:items-center justify-center ml-0 md:ml-8 md:w-1/2">
                            <div class="flex-col justify-center lg:justify-start items-center">
                                {/* <div class="w-full flex justify-center md:justify-start"><h2 class="flex justify-center items-center font-bold text-xl text-gray-300 py-2 rounded-md"><img src="https://gatsycms.vantagecircle.com/wp-content/uploads/2021/10/play-webinar.png"/>&nbsp; Webinar Series</h2></div> */}
                                <div className = "flex justify-center items-center md:justify-start"><img src = "https://gatsycms.vantagecircle.com/wp-content/uploads/2022/03/Vantage_Points_Logo_With_Chester-01.jpg"  height = "280" width= "200" /></div>
                                <div class="w-full flex justify-center md:justify-start"><h1 class="text-center md:text-left font-bold text-4xl xl:text-4xl 2xl:text-7xl py-1 text-gray-100 mt-4">
Employee Engagement in a Hybrid, Post-COVID world</h1></div>
                                <div class="w-full flex justify-center md:justify-start relative">
                                    <h2 class="text-gray-300 my-3 text-center md:text-left">Today's Guest Speaker</h2>
                                    <div className = "border-t border-gray-300 w-1/3 absolute inset-x-48 bottom-1/2 hidden lg:block"></div>
                                </div>
                                
                                <h2 className = "font-bold text-center md:text-left text-purple-100 text-xl md:pb-1 lg:py-2">- Chester Elton, <span className = "font-light">CHRO and co-founder, DummyCo</span></h2>
                                <h2 className = "font-bold text-center md:text-left text-purple-100 text-xl md:pb-1 lg:py-2">- Adrian Gostick, <span className = "font-light">CHRO and co-founder, DummyCo</span></h2>
                                <div class="sm:flex mb-2 w-full mt-5 flex flex-col md:flex-row justify-center md:justify-start">
                                <div class = "flex justify-center items-center hubsButton">
                                    <HubspotForm
                                    portalId='6153052'
                                    formId='78f88c20-751c-4b8e-8a56-4e524ec5b632'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                                </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className = "w-full place-content-center">
                        <div className = "grid grid-cols-2 justify-center items-center">
                        
                        </div>
                    </div> */}
                </section>
                <section class="my-5 w-full flex justify-center">
                        <div class="w-full md:max-w-4xl h-auto px-2 py-5 md:py-7 flex flex-col md:flex-row md:justify-around items-center shadow-lg rounded-lg scale-95 md:scale-100 bg-purple-100 border border-gray-150">
                            <div class="flex w-full justify-center mr-0 md:mr-8 transform scale-100 md:scale-100 md:mt-0">
                                <img class="" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/webinar-date-purple.png" height = "28" width = "28"/>
                                <h3 class="text-gray-800 font-bold text-lg">&nbsp; 20th April 2022</h3>
                            </div>
                            <div class="flex w-full justify-center ml-0 md:ml-8 transform scale-100 md:scale-100 mt-3 md:mt-0">
                                <img class="" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/03/time-ico.png" height = "28" width = "28"/>
                                <h3 class="text-gray-800 font-bold text-lg">&nbsp; COMING SOON!</h3>
                            </div>
                            <div class="flex w-full justify-center ml-0 md:ml-8 transform scale-100 md:scale-100 mt-3 md:mt-0">
                                <img class="" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/09/webinar-venue-purple.png" height = "28" width = "28"/>
                                <h3 class="text-gray-800 font-bold text-lg">&nbsp; ONLINE</h3>
                            </div>
                        </div>
                </section>
                <section className = "flex place-content-center w-full my-8 xl:my-16">
                    <div className = "flex flex-col items-start justify-center w-11/12 md:w-5/6">
                        <div className = "w-7/12 md:w-3/12 xl:w-2/12 2xl:w-1/6 flex justify-start px-9 py-2 xl:py-2 bg-indigo-100 rounded-full relative">
                            <h2 className = "text-purple-100 w-full">Episode</h2>
                            <div className = "w-9 h-9 flex items-center justify-center bg-orange text-white rounded-full absolute right-1 bottom-1">1</div>
                        </div>
                        <h2 className = "font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-left mt-9 mb-6 text-gray-500">Redefining the work culture remotely</h2>
                        <p className = "text-lg md:text-xl xl:text-2xl text-left text-gray-500" style = {{ lineHeight: '2.2rem'}}>There are impact players in every organization. They're the employees who can be relied on in a crisis and who consistently land high-profile assignments and fresh opportunities. Managers see the worth of these excellent players and want more of them on their team, whether they are on the field or behind the scenes.</p>
                    </div>
                </section>
                <section className = "flex place-content-center w-full">
                    <div className = "grid grid-cols-1 lg:grid-cols-2 w-full md:w-5/6 px-5 md:px-10 py-2 md:py-5 rounded-md justify-center items-center" style = {{background: "rgb(240,244,253)",
                        background: "-moz-linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)",
                        background: "webkit-linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)",
                        background: "linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)"}}>
                        <div class="card-1 w-full mb-6 md:mb-0 mt-5">
                            <p class="text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl font-bold pt-3 pb-1 text-gray-500">Key Takeaways</p>
                            <ul class="list-inside orangebullets text-gray-500 lg:text-lg mb-5">
                                <li>Who are the impact players in an organization?</li>
                                <li>How does the Impact player lead?</li>
                                <li>Why do some people break through and make an impact while others get stuck going through the motions?</li>
                            </ul>
                        </div>
                        <div className = "w-full flex place-content-center mb-6 md:mb-0 mt-5">
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/03/Key-takeaways-img.png" />
                        </div>
                    </div>
                </section>
                <section className = "w-full flex-col place-items-center my-8 xl:my-20" id = "about">
                    <div className = "flex flex-col place-items-center text-center w-full mb-9">
                        <h2 className = "text-gray-500 text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl font-bold">About <span className = "text-purple-500">Vantage Point Webinar</span></h2>
                        <p className = "w-5/6 my-7 text-gray-500 text-lg md:text-xl xl:text-2xl" style = {{ lineHeight: '2.2rem'}}>There are impact players in every organization. They're the employees who can be relied on in a crisis and who consistently land high-profile assignments and fresh opportunities. Managers see the worth of these excellent players and want more of them on their team, whether they are on the field.</p>
                    </div>
                    <div className = "w-full flex place-content-center py-20 relative" style = {{background: "rgb(240,244,253)",
                        background: "-moz-linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)",
                        background: "webkit-linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)",
                        background: "linear-gradient(148deg, rgba(240,244,253,1) 0%, rgba(250,252,255,1) 100%)"}}>
                        <div className = "w-full md:w-5/6 flex flex-col md:flex-row justify-center items-center">
                            <div className = "w-1/3 flex flex-col justify-center items-center pt-3">
                                <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/03/Partha_CTONote.png" />
                                <h2 className = " mt-3 font-bold text-gray-500 text-xl">Partha Neog</h2>
                                <p className = " mt-3 text-gray-500 text-xl text-center">CEO & Co-founder, Vantage Circle</p>
                            </div>
                            <div className = "w-11/12 md:w-2/3 flex flex-col justify-center items-start">
                                <h2 className = "font-bold text-gray-500 text-3xl pt-10 md:pt-0 md:pl-20">CEO's <span className = "underlined xl:big">Note</span></h2>
                                <p className = "text-gray-500 text-lg md:text-xl xl:text-2xl italic md:border-l border-gray-600 my-3 md:my-8 leading-10 md:pl-20" style = {{ lineHeight: '2.2rem'}}>
                                    There are impact players in every organization. They're the employees who can be relied on in a crisis and who consistently land high-profile assignments and fresh opportunities. Managers see the worth of these excellent players and want more of them on their team, whether they are on the field or behind the scenes.
                                </p>
                            </div>
                        </div>
                        <div className = "absolute md:left-28 xl:left-40 -top-8">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" height="100" width="60" class="opacity-30" />
                        </div>
                    </div>
            </section>
            <section className = "flex flex-col place-items-center w-full relative" id="guestspeakers">
                <h2 className = "font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-left w-11/12 md:w-10/12 xl:w-10/12 text-gray-500 py-9">Guest Speaker</h2>
                <div className = "w-full flex flex-col items-center justify-center bg-indigo-100 py-16 relative z-1 overflow-hidden">    
                    <div className = "grid grid-cols-1 md:grid-cols-3 gap-20 justify-center items-center w-full md:w-5/6 lg:w-11/12 xl:w-10/12 pl-5 md:pl-0 lg:pl-8 xl:px-0 relative z-1">
                        <div className = "flex justify-start lg:justify-center">
                            <div className = "flex flex-col">
                                <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/03/Matt-Burns-VP.png" width= "200" height = "200"/>
                                <h2 className = "font-bold text-gray-100 text-xl md:text-2xl xl:text-3xl 2xl:text-4xl py-3">Matt Dane</h2>
                                <p className = "text-gray-10">HR Leader & Keynote Speaker,<br/>PeaceHR</p>
                                <p className = "text-gray-100 italic">Matt Dane is Chief People Officer of The Granite Group, based in Concord, NH. Tracie is a global speaker on HR, leadership, technology, Software Reviews.</p>
                            </div>
                        </div>
                        <div className = "flex justify-start lg:justify-center ">
                            <div className = "flex flex-col">
                                <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/03/Matt-Burns-VP.png" width= "200" height = "200"/>
                                <h2 className = "font-bold text-gray-100 text-xl md:text-xl xl:text-3xl 2xl:text-4xl py-3">Matt Dane</h2>
                                <p className = "text-gray-10">HR Leader & Keynote Speaker,<br/>PeaceHR</p>
                                <p className = "text-gray-100 italic">Matt Dane is Chief People Officer of The Granite Group, based in Concord, NH. Tracie is a global speaker on HR, leadership, technology, Software Reviews.</p>
                            </div>
                        </div>
                    </div>
                    <svg className="absolute -right-10 -top-9 -z-1 lace-wrapper1 flex opacity-40 md:opacity-100" version="1.1" id="Layer_1" x="0px" y="0px"
                            viewBox="0 0 442.6 523.6">

                                <path class="curve" d="M30,32.8c0,0,81.2,231.5,177.8,182.2c39.7-20.2,47.5-53.6,47.8-75.4c0.2-13.9-4.1-27.5-12.4-36.2
                            c-5-5.3-11.9-9.2-20.6-8.2C189.2,99,144.1,219.4,190.6,232c32.8,8.9,81.5-9.7,81.5-9.7s123.1-43.9,111.1,97.2
                            c-9.3,56.3-25.2,62.3-38.8,99.5c-16.1,44.1,3.7,113.6,63.7,53.1"/>
                            </svg>
                </div>
                    {/* <img loading="lazy" class="cta-dot-clip-2lt z-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1623747897/gatsbycms/uploads/2021/06/circle-purple-half.png" alt="VC purple dots" width="55" height="100" />
                    <img loading="lazy" class="cta-dot-clip-2rb z-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1623747897/gatsbycms/uploads/2021/06/circle-purple-half.png" alt="VC purple dots" width="55" height="100"/> */}
            </section>
<section>
    <div class="container max-w-7xl mx-auto px-6 py-10 md:py-20" id ="hosts">
        <h2 className = "font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-center text-gray-500 py-9">
            About the Hosts
        </h2>
        <div class="max-w-3xl mx-auto mt-10 grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                    <div class="relative">
                        <a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/chester-elton-advisors.png" alt="Chester Elton" width="366" height="422" />
                            </a><a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                            <div class="text-center absolute bottom-0 p-4">
                                <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Chester Elton</p>
                                <p class="text-white text-sm sm:text-base mb-2">#1 Bestselling Business Author, Leadership Expert, Organizational Culture Expert, Employee Engagement and Teamwork Expert, Global Keynote Speaker.</p>
                            </div>
                        
                    </div>
                    <div class="absolute adv-detail">
                            <p class="text-white text-sm sm:text-base mb-0">Chester Elton is a #1 Bestselling Business Author, #4 among the world’s top leadership experts and #2 among the world’s top organizational culture experts. He is a member of Marshall Goldsmith 100 Coaches, an Executive Coach, Keynote Speaker, and Founder of the #findyourgratitude Community. In the last two decades, Chester has helped some of the world’s most successful businesses engage their employees to execute on strategy, vision, and values.</p>
                    </div>
            </div>
            <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                    <div class="relative">
                        <a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener">
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/chester-elton-advisors.png" alt="Chester Elton" width="366" height="422" />
                            </a><a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                            <div class="text-center absolute bottom-0 p-4">
                                <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Chester Elton</p>
                                <p class="text-white text-sm sm:text-base mb-2">#1 Bestselling Business Author, Leadership Expert, Organizational Culture Expert, Employee Engagement and Teamwork Expert, Global Keynote Speaker.</p>
                            </div>
                        
                    </div>
                    <div class="absolute adv-detail">
                            <p class="text-white text-sm sm:text-base mb-0">Chester Elton is a #1 Bestselling Business Author, #4 among the world’s top leadership experts and #2 among the world’s top organizational culture experts. He is a member of Marshall Goldsmith 100 Coaches, an Executive Coach, Keynote Speaker, and Founder of the #findyourgratitude Community. In the last two decades, Chester has helped some of the world’s most successful businesses engage their employees to execute on strategy, vision, and values.</p>
                    </div>
            </div>
        </div>
    </div>
</section>

                <section className=''>
                    <WebcastCarousel />
                </section>

                 <section className = "w-full flex place-content-center px-6 bg-gray-100" id = "faqs">
                <div class="container max-w-full w-full lg:w-11/12 xl:w-10/12 2xl:w-8/12 mx-auto md:px-6 md:pb-16 md:pt-20 flex flex-col justify-around items-center">
                    <div class="text-center pb-5 md:pb-14 pt-10 my-1 md:pt-0 md:mr-5">
                        <h2 class="text-center text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-gray-500"><span className = "font-light text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl">Got Questions?<br/> </span>Read our <span className = "underlined xl:big">FAQs.</span></h2>
                    </div>
                    <div class="career-faq p-2 md:p-5 w-full mb-10 md:ml-5">
                    <div class="px-1 py-5 toc-detail">
                        <div class="container max-w-full">
                            <div class="acc relative">
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading active">1. What is the Vantage Point webcast series?</div>
                                    <div class="acc__panel" style={{display: "block", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    The Vantage Point Webcast is a six-episode series where the world-renowned Author and Executive Coach Chester Elton and Adrian Gostick will host top Global Thought Leaders and Business Honchos. Each show will feature the Hosts and guests discussing and sharing possible solutions to some of the most important, trending, and burning HR and Leadership topics. 
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">2. Who should join?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    This webcast is intended for top senior management employees, corporate leaders, CHROs, CXOs, CPOs, and other senior HR managers. 
                                    </div>
                                </div>

                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">3. What is the duration of each webcast?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Each webcast session will last between 50 and 60 minutes.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">4. What is the number of episodes in the Vantage Point webcast series?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Starting on April 20th, 2022, there will be six webcast episodes that will run until September. One episode is scheduled for each coming month from April onwards. 
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">5. Are the webcasts pre-recorded?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    We will not pre-record the webcasts, and these will all be live events.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">6. What platform do we need to have for live-streaming?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Since the webcasts will be hosted on Zoom, you should download Zoom on your computer or mobile device to access the session after clicking the joining link shared with you in the email.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">7. Do we need a paid pass or tickets to join the live sessions?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    You don’t need to pay for passes, but the passes are limited, and we are giving them on a first-come and first-serve basis.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">8. How to register for passes?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    The limited free passes can be availed by registering using the ‘fill up the form’ window in our webcast registration page. Once submitted, you’ll receive an acknowledgment and the Pass with all the important details to join the webcast series.
                                    </div>
                                </div>





                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">9. How to join the webcast?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    To join the webcast, simply click on the webcast joining link that we will send by email before each Live Webcast Episodes. On clicking the link, you’ll be prompted to use the zoom app installed on your system, and you’ll be able to access the live Webcast Session.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">10. Will you have to register to attend more than one webcast in the first season of Vantage Point webcast series 22 separately?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    There is no need to register separately for each webcast. You can access all the webcasts in this series using the links we send by email. We will also send one reminder email with the link one hour before each webcast.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">11. Whom to connect with if you face any problem while joining the webcast?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    If you face any issues while joining the webcast, please reach out to us at partner@vantagecircle.com. 
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">12. How to participate in the Q&A session during the webcast?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        Once you’re inside the webcast, you’ll have the option in zoom to type in your question for our panelist. However, there’ll be a designated slot where the hosts/guests will be answering a few of the relevant questions asked through the chatbox.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">13. How to participate in the polling during the webcast?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Our hosts will initiate the in-session polls, and they’ll be up on your screens. You can select the given options and mark your participation in the poll.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">14. Can I register with my personal email?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    We only accept registrations from your business email accounts.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">15. Are there any SHRM CREDITs (PDCs)?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Yes, this webcast series has offered SHRM PDCs to Qualified HR professionals.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">16. What is the process to get the CREDITs (PDCs)?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Register for the event using our registration link and fill in the form. Once registered, we will send you the event link. You have to participate in the webcast series episodes to claim PDCs. Once the webcasts are over, we will send codes to claim your PDC.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">17. How many CREDITs (PDCs) are available?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Six hours (approx.) SHRM PDCs are available. You may choose to attend one or all episodes to claim the PDCs. Based on the episode durations, you will get 0.75 or 1 PDC for each episode. Make sure you leave after the episode ends to claim it.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">18. Will we get any certificate for participation?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Yes, a 'Certificate of Participation' with PDC will be sent to all eligible participants after the series.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">19. Can we have post-event access to the recordings of the event? </div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    Yes, the content from Vantage Point Webcast episodes will be available on demand. Watch the webcast LIVE on the designated days to ensure you don’t miss out!
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">20. For any more information or clarification, contact us.</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                    If you have any more questions, we would love to hear from you! Send us your queries at partner@vantagecircle.com.
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </Layout>
    )
}

export default registration
